
.iframe .logo img{
    width: 32px;
    /*margin: 15px 24px 15px 0;*/
    float: left;
}
.iframe .logo{
    float: left;
    width: 175px;
    height: 32px;
    margin: 16px 24px 16px 0;
}
.iframe .name{
    display: inline-block;
    font-size: 18px;
    position: absolute;
    color: #ffffff;
    left:55px;
    top: 0px;
    font-weight: bold;
}
.iframe .header_right {
    float: right;
    color: white;
}
.iframe .header_role {
    /*float: right;*/



    color: white;
    display: inline-block;
    margin-right: 8px;
}
.iframe .ant-menu-submenu-title:hover{
    color:#fff
}

.iframe {
    min-height: 100vh;
}
.layout .ant-menu-submenu-title:hover{
    color: #fff ;
}