.out_layout .layout .logo img{
    width: 45px;
    /*margin: 15px 24px 15px 0;*/
    float: left;
}
.out_layout .layout .logo{
    float: left;
    width: 220px;
    height: 45px;
    margin: 16px 24px 16px 0;
}
.out_layout .layout .name{
    display: inline-block;
    font-size: 24px;
    position: absolute;
    left:55px;
    top: 2px;
    font-weight: bold;
    color: #0d0d0d;
}
.out_layout .layout{
    padding: 50px 70px 0 70px;
    background: none;
    min-height: 100vh;
}
.out_layout .layout .ant-layout-header{
    height: 80px;
    line-height:79px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.out_layout .layout .ant-layout-content{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.out_layout .layout .header_right {
    float: right;
    color: #000000;
}
.out_layout .layout .header_role {
    /*float: right;*/
    color: #000000;

    display: inline-block;
    margin-right: 8px;
}

.out_layout{
    background: url("/img/home_bg.png") no-repeat 0 0/cover;;
}